<template>
  <main id="cart">
    <bar-breadcrumbs :data="breadcrumbs" />
    <section class="bar__categories">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full px-4">
            <header-h3
              :content="$t('menu.cart')"
              align="center"
              casing="uppercase"
              mt="0"
              mb="0"
            />

            <div
              v-if="!isCartLoaded"
              class="h-screen w-full flex flex-col items-center justify-center bg-white bg-opacity-75 inset-0 z-50 fixed">
              <p class="font-bold uppercase text-xl mb-4">Loading...</p>
              <img src="~/assets/images/loading.svg">
            </div>
            <div
              v-if="isCartError"
              v-html="$t('components.cart.cart_loading_error')"
              class="my-4 bg-orange text-white px-4 py-2">
            </div>
            <div v-else-if="cartQty === 0">
              <div
                v-html="$t('components.cart.empty')"
                class="my-4 bg-orange text-white px-4 py-2"
              />
            </div>
            <div v-else>
              <div class="flex flex-wrap -mx-4">
                <div class="w-full px-4 mt-6">
                  <table class="table">
                    <thead>
                      <tr>
                        <td
                          v-html="$t('components.cart.headers.image')"
                          class="no_mobile_col"
                        />
                        <td
                          v-html="$t('components.cart.headers.product_name')"
                        />
                        <td v-html="$t('components.cart.headers.quantity')" />
                        <td
                          v-html="$t('components.cart.headers.price')"
                          class="price_column"
                        />
                        <td v-html="" />
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(cartItem, index) in cartItems">
                        <td class="no_mobile_col">
                          <img
                            :src="cartItem.product.image"
                            :alt="cartItem.product.name"
                            class="w-32"
                          />
                        </td>
                        <td>
                          <nuxt-link
                            :to="localePath({ name: 'search-product', params: { product: cartItem.product.slug }})"
                            v-html="cartItem.product.name"
                          />
                          <br>
                          <small>
                            <strong v-t="'components.products.sku'" />
                            <span v-html="cartItem.product.sku" />
                          </small>
                        </td>
                        <td v-if="edit_allowed === index">
                          <input
                            v-model="cartItem.quantity"
                            type="number"
                            class="border border-rounded border-solid border-gray-medium w-24 text-center p-2"
                          />
                          <font-awesome-icon
                            @click="changeQuantity(cartItem)"
                            :icon="['fas', 'save']"
                            class="cursor-pointer" />
                        </td>
                        <td v-else>
                          <span v-html="cartItem.quantity" />
                          <font-awesome-icon
                            @click="editAllowed(index)"
                            :icon="['fas', 'pencil-alt']"
                            class="cursor-pointer" />
                        </td>
                        <td v-html="getPriceFormatted(cartItem.unit_price)" />
                        <td>
                          <button
                            @click="removeItem(cartItem.id)"
                            v-html="'X'"
                            type="button"
                          />
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td v-html="" class="no_mobile_col" />
                        <td
                          v-html="$t('components.cart.headers.sub_total')"
                          colspan="2"
                        />
                        <td>
                          {{ getPriceFormatted(sub_total) }}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <div class="flex flex-wrap -mx-4">
                <div class="w-full tablet:w-1/2 px-4 mt-6">
                  <button-primary
                    :text="$t('components.cart.continue_shopping')"
                    path="products"
                  />
                </div>
                <div class="w-full tablet:w-1/2 tablet:text-right px-4 mt-6">
                  <button-primary
                    :text="$t('components.cart.order_now')"
                    path="cart-data"
                  />
                  <p class="mt-6">
                    <button
                      @click="clearCart"
                      v-html="$t('components.cart.empty_cart')"
                      class="button button--tertiary"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import BarBreadcrumbs from '../../components/bars/Breadcrumbs';
import CommonUtils from '/helpers/CommonUtils';

export default {
  name: 'Cart',
  components: { BarBreadcrumbs },
  head() {
    const title = 'Cart - Air Conditioning Parts - AP Air LTD.';
    const description = '';

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.apairltd.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'AP Air LTD.' }
      ]
    }
  },
  data () {
    return {
      breadcrumbs: [{
        name: this.$t('menu.home'),
        url: 'index'
      }, {
        name: this.$t('menu.cart'),
        url: 'cart'
      }],
      currency: 'EUR',
      edit_allowed: null,
      locale: this.$i18n.locale,
      loggedIn: this.$auth.loggedIn,
      tax: 0,
      cartItems: []
    }
  },
  computed: {
    isCartLoaded () {
      return this.$store.state.isCartLoaded;
    },
    isCartError () {
      return this.$store.state.isCartError;
    },
    cartQty () {
      return this.$store.state.cartQty;
    },
    rawCartItems () {
      return this.$store.state.cartItems;
    },
    sub_total () {
      if (this.cartItems.find(item => item.currency !== this.currency)) {
        console.warn('inconsistent cart items/s currency, should be,', this.currency);
      }
      return this.cartItems.reduce((sum, item) => sum + Number(item.total_price), 0);
    },
    transport () {
      /* const tempCartItems = this.$store.state.cartItems
      let transport = 0.0
      for (let i = 0; i < tempCartItems.length; i++) {
        for (let j = 0; j < tempCartItems[i].transportRates.length; j++) {
          if (tempCartItems[i].transportRates[j].countryid === 528) {
            if (
              transport < parseFloat(tempCartItems[i].transportRates[j].amount)
            ) {
              transport = parseFloat(tempCartItems[i].transportRates[j].amount)
            }
          }
        }
      }
      return transport */
      return 0;
    }
  },
  watch: {
    isCartLoaded (newVal, oldVal) {
      if (newVal) {
        this.cartItems = JSON.parse(JSON.stringify(this.$store.getters.getCartItems));
      }
    }
  },
  created () {
    this.cartItems = JSON.parse(JSON.stringify(this.$store.getters.getCartItems));
    this.setCurrency();
  },
  methods: {
    async clearCart () {
      await this.$store.dispatch('clearCart');

      this.$nextTick(() => {
        this.cartItems = JSON.parse(JSON.stringify(this.$store.getters.getCartItems));
      });
    },
    async removeItem (cartItemID) {
      await this.$store.dispatch('removeItemFromCart', cartItemID);

      this.$nextTick(() => {
        this.cartItems = JSON.parse(JSON.stringify(this.$store.getters.getCartItems));
        this.edit_allowed = null;
      });
    },
    editAllowed (index) {
      this.edit_allowed = index;
    },
    async changeQuantity (cartItem) {
      if (Number(cartItem.quantity) > 0) {
        await this.$store.dispatch('updateCartItemQuantity', { cartItemID: cartItem.id, newQuantity: cartItem.quantity });
      } else {
        this.removeItem(cartItem.id);
        return;
      }

      this.$nextTick(() => {
        this.cartItems = JSON.parse(JSON.stringify(this.$store.getters.getCartItems));
        this.edit_allowed = null;
      });
    },
    setCurrency () {
      const priceList = this.$store.state.priceList;
      this.currency = CommonUtils.getCurrencyForPriceList(priceList);
    },
    getPriceFormatted (price, currentCurrency = null) {
      let currency = currentCurrency || this.currency;
      if (!price) {
        return 'P.O.A.';
      }

      return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: currency
      }).format(price);
    }
  }
}
</script>
