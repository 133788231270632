<template>
  <main id="cart">
    <bar-breadcrumbs :data="breadcrumbs" />
    <section class="bar__categories">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full px-4">
            <header-h3
              :content="$t('components.cart.headers.thank_you')"
              align="center"
              casing="uppercase"
              mt="0"
              mb="4"
            />
            <p v-html="$t('components.cart.thank_you')" class="text-center" />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import BarBreadcrumbs from '../../components/bars/Breadcrumbs'

export default {
  name: 'Cart',
  components: { BarBreadcrumbs },
  head() {
    const title = 'Cart - Air Conditioning Parts - AP Air LTD.'
    const description = ''

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.apairltd.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'AP Air LTD.' }
      ]
    }
  },
  data() {
    return {
      loggedIn: this.$auth.loggedIn,
      breadcrumbs: [
        {
          name: this.$t('menu.home'),
          url: 'index'
        },
        {
          name: this.$t('menu.cart'),
          url: 'cart'
        },
        {
          name: this.$t('components.cart.headers.thank_you'),
          url: 'cart-thankyou'
        }
      ]
    }
  },
  mounted() {
    const rawCartItems = this.$store.getters.getCartItems;
    const purchaseItems = []
    let subtotal = 0.0

    for (let i = 0; i < rawCartItems.length; i++) {
      const cartItem = {
        item_name: rawCartItems[i].product.name,
        item_id: rawCartItems[i].product.id,
        price: parseFloat(rawCartItems[i].unit_price),
        quantity: rawCartItems[i].quantity
      }

      subtotal += rawCartItems[i].total_price;
      purchaseItems.push(cartItem)
    }

    const purchaseObject = {
      event: 'purchase',
      ecommerce: {
        affiliation: 'AP AIR LTD.',
        value: subtotal,
        currency: rawCartItems.length ? rawCartItems[0].currency : '-',
        items: purchaseItems
      }
    }

    window.dataLayer.push(purchaseObject);

    this.$store.dispatch('clearCart');
  }
}
</script>
